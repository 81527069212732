import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useRef, useState} from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    readMoreClass: {
        display: '-webkit-box',
        lineClamp: 3,
        boxOrient: 'vertical',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    readLessClass: {
        cursor: 'pointer'
    },
    expandContainer: {
        textAlign: 'center'
    },
    normal: {
        display: '-webkit-box',
        lineClamp: 3,
        boxOrient: 'vertical',
        overflow: 'hidden',
    },
    textContainer: {
        minHeight: (theme.typography.body1.lineHeight * 4) + 'em'
    },
    button: {
        cursor: 'pointer'
    }
}))

export default function ReadMoreText({text}) {

    const classes = useStyles();
    const ref = useRef(null)

    const [hasOverflow, setHasOverflow] = useState(false)
    const [isShowAll, setIsShowAll] = useState(false)

    useEffect(() => {
        setTimeout(function () {
            requestAnimationFrame(updateState)
        }, 1000)    }, [])

    function updateState() {
        if (ref && ref.current) {
            if (ref.current.scrollHeight > ref.current.clientHeight) {
                setHasOverflow(true)
            }
        }
    }

    function renderButton() {
        if (hasOverflow && !isShowAll) {
            return <span className={classes.button} onClick={() => setIsShowAll(true)}><ExpandMoreIcon/></span>
        } else if(isShowAll) {
            return <span className={classes.button} onClick={() => setIsShowAll(false)}><ExpandLessIcon/></span>
        }
        return null
    }

    function renderText() {
        if (isShowAll) {
            return <Typography className={classes.readLessClass} onClick={() => setIsShowAll(false)} variant="body1" ref={ref}>{text}</Typography>
        }
        if (hasOverflow) {
            return <Typography className={classes.readMoreClass} onClick={() => setIsShowAll(true)} variant="body1" ref={ref}>{text}</Typography>
        }
        return <Typography className={classes.normal} variant="body1" ref={ref}>{text}</Typography>
    }

    return (
        <div className={classes.textContainer}>
            {renderText()}
            <div className={classes.expandContainer}>
                {renderButton()}
            </div>
        </div>
    )
}
