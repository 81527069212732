import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, TextField} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useLocation, useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        paddingTop: '20px'
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subtitle: {
        paddingTop: "10px",
    },
    saveButtonContainer: {
        padding: "50px"
    }
}));

export default function ExperienceStep({survey, response, saveResponse, getNextUrl}) {

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [localResponse, setLocalResponse] = useState({})

    useEffect(() => {
        const tempResponse = Object.assign({}, response)
        tempResponse.name = response.name ? response.name : ''
        tempResponse.role = response.role ? response.role : ''
        tempResponse.experience = response.experience ? response.experience : 0
        setLocalResponse(tempResponse)
        setIsLoading(false)
    },[response]);

    useEffect(() => {
        if (localResponse.name && localResponse.role) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [localResponse])

    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                try {
                    saveResponse(localResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [saveResponse, localResponse, location])

    const createMenuItems = () => {
        const menuItems =  [];
        menuItems.push(<MenuItem key={0} value={0}>None</MenuItem> )
        for (let i  = 1; i < 25; i++) {
            menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }
        menuItems.push(<MenuItem key={"25+"} value={"25+"}>25+</MenuItem>)
        return menuItems;
    }

    const setYearsExperience = (years) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.experience = years
        setLocalResponse(tempResponse)
    }

    const setName = (name) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.name = name
        setLocalResponse(tempResponse)
    }

    const setCurrentRole = (role) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.role = role
        setLocalResponse(tempResponse)
    }

    const renderSaveButton = () => {
        if (isSaving) {
            return <CircularProgress/>
        }
        if (isDisabled) {
            return <Button size="large" variant="contained" color="primary" disabled>Next →</Button>
        }
        return <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
    }

    return (
        <React.Fragment>
                {
                    isLoading ?
                        <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                            <CircularProgress/>&nbsp;
                            <div>
                                <Typography variant="subtitle1">Loading</Typography>
                            </div>
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Typography align="center" variant="h6">Please enter your name</Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: "45px"}}>
                                <Grid item lg={10}>
                                    <form noValidate autoComplete="off">
                                        <div style={{textAlign: "center"}}>
                                            <FormControl className={classes.formControl}>
                                                <TextField id="name" value={localResponse.name} onChange={(event) => setName(event.target.value)}/>
                                            </FormControl>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Typography align="center" variant="h6">What is your current role?</Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: "45px"}}>
                                <Grid item lg={10}>
                                    <form noValidate autoComplete="off">
                                        <div style={{textAlign: "center"}}>
                                            <FormControl className={classes.formControl}>
                                                <TextField id="currentRole" value={localResponse.role} onChange={(event) => setCurrentRole(event.target.value)}/>
                                            </FormControl>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Typography align="center" variant="h6">How many years experience do you have?</Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: "15px"}}>
                                <Grid item lg={10}>
                                    <form noValidate autoComplete="off">
                                        <div style={{textAlign: "center"}}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="years-experience-label">Years</InputLabel>
                                                <Select
                                                    labelId="years-experience-label"
                                                    id="years-experience-select"
                                                    value={localResponse.experience}
                                                    onChange={(event) => setYearsExperience(event.target.value)}
                                                    label="years"
                                                >
                                                    {createMenuItems()}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }

            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                    {renderSaveButton()}
                </Grid>
            </Grid>
        </React.Fragment>

    );
}
