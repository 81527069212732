import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    Hidden,
    Slider
} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ReadMoreText from "../util/ReadMoreText";
import {getSelfRatingDescription, getSelfRatingMarks} from "../../service/util";


const SelfRatingSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: 8,
    },
    active: {},
    mark: {
        height: 4
    },
    track: {
        height: 4,
        borderRadius: 2,
    },
    rail: {
        height: 4,
        borderRadius: 2,
    },
}), { withTheme: true })(Slider);


const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center",
        paddingBottom: '10px'
    },
    subHeading: {
        paddingBottom: "10px"
    },
    categoryContainer: {
        borderBottom: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#afafaf'
    },
    categoryContainerLeft: {
        borderRight: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#afafaf'
    },
    categoryTextContainer: {
        padding: '10px',
        position: 'sticky',
        top: 0
    },
    categoryTextContainerMobile: {
        width: '100vw',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginLeft: '-15px',
        marginRight: '-15px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        boxShadow: '0px 2px 6px #6b6b6b',
        background: "white"
    },
    categoryTitle: {
        paddingBottom: '5px',
        fontWeight: 'bold'
    },
    skillTitle: {
        fontWeight: 'bold',
        paddingBottom: '6px'
    },
    prompt: {
        fontWeight: 'bold'
    },
    skillItemDescription: {
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden'
    },
    skillItemHeaderDiv: {
        padding: 20
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    skillItem: {
        margin: '20px',
        textAlign: "left",
        border: "solid",
        borderWidth: '1px',
        borderRadius: '15px',
        borderColor: '#afafaf'
    },
    skillSectionContainer: {
        paddingTop: 20
    },
    joyRatingContainer: {
        textAlign: 'center'
    },
    joyRatingText: {
        padding: '5px',
        fontSize: "small"
    },
    joyRatingTextSelected: {
        fontSize: "small",
        fontWeight: "bold"
    },
    joyRatingAvatarContainer: {
        cursor: "pointer"
    },
    joyRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    joyRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingContainer: {
        margin: "10px",
        minHeight: "92px"
    },
    skillRatingTitle: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: "small",
        backgroundColor: '#efefef'
    },
    saveButtonContainer: {
        padding: "50px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    }
}))

export default function SelectJoyAndSelfRatingSegment({survey, segment, response, saveResponse, getNextUrl, isReview}) {

    const classes = useStyles();

    const location = useLocation()

    const [isLoading, setIsLoading] = useState(true);
    const [categorizedSkills, setCategorizedSkills] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        const tempResponse = Object.assign(response, {})
        const categorizedSkills = {}

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.isUsing = tempResponse.skillGroups[skillGroupId][skillId].isUsing ? tempResponse.skillGroups[skillGroupId][skillId].isUsing : false
                skill.isSelected = tempResponse.skillGroups[skillGroupId][skillId].isSelected ? tempResponse.skillGroups[skillGroupId][skillId].isSelected : false
                skill.joyRating = tempResponse.skillGroups[skillGroupId][skillId].joyRating ? tempResponse.skillGroups[skillGroupId][skillId].joyRating : 2
                skill.selfRating = tempResponse.skillGroups[skillGroupId][skillId].selfRating ? tempResponse.skillGroups[skillGroupId][skillId].selfRating : 1

                if (!categorizedSkills[skillGroupId]) {
                    categorizedSkills[skillGroupId] = []
                }
                categorizedSkills[skillGroupId].push(skill)
            })
        })

        setCategorizedSkills(categorizedSkills)

        setIsLoading(false)
    },[segment, survey, response]);

    //Save any time the user navigates away from this component
    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                let tempResponse = Object.assign({}, response)

                Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
                    skills.forEach(skill => {
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].isUsing = skill.isUsing
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].isSelected = skill.isSelected
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].category = skill.category

                        tempResponse.skillGroups[skill.skillGroupId][skill.id].selfRating = skill.selfRating
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].joyRating = skill.joyRating
                    })
                }))

                try {
                    saveResponse(tempResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [response, saveResponse, categorizedSkills, location])

    const isUsing = (index, skillGroupId) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)
        tempCategorizedSkills[skillGroupId][index].isUsing = !tempCategorizedSkills[skillGroupId][index].isUsing
        tempCategorizedSkills[skillGroupId][index].isSelected = tempCategorizedSkills[skillGroupId][index].isUsing;
        setCategorizedSkills(tempCategorizedSkills)
    }

    const selfRating = (index, skillGroupId, rating) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)
        tempCategorizedSkills[skillGroupId][index].selfRating = rating
        setCategorizedSkills(tempCategorizedSkills)
    }

    const joyRating = (index, skillGroupId, rating) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)
        tempCategorizedSkills[skillGroupId][index].joyRating = rating
        setCategorizedSkills(tempCategorizedSkills)
    }

    function buildSkillsList(skills, skillGroupId) {

        const skillsJsx = skills.map((skill, index) => {
            return skillJsx(skill, index, skillGroupId)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    const skillJsx  = (skill, index, skillGroupId) => {
        return (
            <Grid key={skill.id} item lg={6}>
                <div className={classes.skillItem}>
                    <div className={classes.skillItemHeaderDiv}>
                        <Typography variant="h6" className={classes.skillTitle}>{skill.title}</Typography>
                        <ReadMoreText text={skill.description}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={skill.isUsing}
                                    onChange={() => isUsing(index, skillGroupId)}
                                    name="checkedIsUsing"
                                    color="primary"
                                />
                            }
                            label={isReview ? response.targetUserGivenName + " has this skill" : "I have this skill"}
                        />
                    </div>
                    <Collapse in={skill.isUsing} collapsedHeight={0}>
                        <Divider/>
                        <div className={classes.skillItemBodyDiv}>
                            <div className={classes.skillSectionContainer}>
                                {selfRatingJsx(skill, index, skillGroupId)}
                            </div>
                            <div className={classes.skillSectionContainer}>
                                {joyRatingJsx(skill, index, skillGroupId)}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </Grid>
        )
    }

    const joyRatingJsx = (skill, index, skillGroupId) => {
        if (isReview) {
            return ''
        }
        return (
            <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} className={classes.subHeadingContainer}>
                        <Typography variant="body1" className={classes.prompt}>How much do you enjoy doing this?</Typography>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center" className={classes.joyRatingContainer}>
                    <Grid onClick={() => joyRating(index, skillGroupId, 1)}  item xs={4} className={classes.joyRatingAvatarContainer}>
                        <div className={skill.joyRating === 1 ? classes.joyRatingAvatarSelected : classes.joyRatingAvatar}>
                            <span role="img" aria-label="frustrated">😩</span>
                        </div>
                        <Typography className={skill.joyRating === 1 ? classes.joyRatingTextSelected : classes.joyRatingText} >Not a fan</Typography>
                    </Grid>
                    <Grid onClick={() => joyRating(index, skillGroupId, 2)} item xs={4} className={classes.joyRatingAvatarContainer}>
                        <div className={skill.joyRating === 2 ? classes.joyRatingAvatarSelected : classes.joyRatingAvatar}>
                            <span role="img" aria-label="content">🙂</span>
                        </div>
                        <Typography className={skill.joyRating === 2 ? classes.joyRatingTextSelected : classes.joyRatingText} >Don't Mind</Typography>
                    </Grid>
                    <Grid onClick={() => joyRating(index, skillGroupId, 3)} item xs={4} className={classes.joyRatingAvatarContainer}>
                        <div className={skill.joyRating === 3 ? classes.joyRatingAvatarSelected : classes.joyRatingAvatar}>
                            <span role="img" aria-label="love">😍</span>
                        </div>
                        <Typography className={skill.joyRating === 3 ? classes.joyRatingTextSelected : classes.joyRatingText} >Love it</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    const selfRatingJsx = (skill, index, skillGroupId) => {
        return (
            <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} className={classes.subHeadingContainer}>
                        <Typography variant="body1" className={classes.prompt}>{isReview ? "How confident is " + response.targetUserGivenName + " with this skill" :"How confident are you at this skill?"}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.skillRatingContainer}>
                        <Typography className={classes.skillRatingTitle}>{getSelfRatingDescription(survey, skill.selfRating)}</Typography>
                    </Grid>
                    <SelfRatingSlider
                        defaultValue={skill.selfRating ? skill.selfRating : 1}
                        getAriaValueText={(value) => {
                            return `${value}`
                        }}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={getSelfRatingMarks(survey).length}
                        onChange={(event, newValue) => selfRating(index, skillGroupId, newValue)}
                        marks={getSelfRatingMarks(survey)}
                        // style={{marginTop: 20}}
                    />
                </Grid>
            </React.Fragment>
        )
    }

    const buildCategorizedSkills = () => {
        const jsx = [];
        Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
            const category = survey.skillGroupsMeta[skillGroupId]
            jsx.push(
                <Grid item key={skillGroupId} xs={12} className={classes.categoryContainer}>
                    <Grid container direction="row">
                        <Hidden smDown>
                            <Grid item md={3} className={classes.categoryContainerLeft}>
                                <div className={classes.categoryTextContainer}>
                                    <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                                    <Typography variant='body1'>{category.description}</Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <div className={classes.categoryTextContainerMobile}>
                                <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                            </div>
                        </Hidden>
                        <Grid item sm={12} md={9}>
                            <Grid container direction="row" spacing={0}>
                                {buildSkillsList(skills, skillGroupId)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }))

        if (jsx.length) {
            return jsx
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography variant='h4' style={{marginBottom: '10px'}}>{isReview ? <span>Select <strong>{response.targetUserGivenName} {response.targetUserFamilyName}'s</strong> level</span>:'Select your level'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#afafaf'}}>
                            <Grid container direction="row" justify="center">
                                {buildCategorizedSkills()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
