import React, {useEffect, useState} from 'react';
import {Button, Chip, CircularProgress, Collapse, Divider, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingTitle: {
        fontSize: "small"
    },
    skillItemHeaderDiv: {
        padding: 20,
        cursor: 'pointer'
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        textTransform: "none"
    },
    skillItem: {
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingAvatarContainer: {
        margin: "10px",
        cursor: 'pointer'
    },
    skillRatingTitleSelect: {
        fontSize: "small",
        fontWeight: "bold"
    }
}))


export default function SelectAndJoyRating({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [localSkills, setLocalSkills] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        const tempResponse = Object.assign(response, {})
        const skills = []

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.isSelected = tempResponse.skillGroups[skillGroupId][skillId].isSelected ? tempResponse.skillGroups[skillGroupId][skillId].isSelected : false
                skill.joyRating = tempResponse.skillGroups[skillGroupId][skillId].joyRating ? tempResponse.skillGroups[skillGroupId][skillId].joyRating : 2
                skills.push(skill)
            })
        })

        setLocalSkills(skills)
        setIsLoading(false)
    },[segment, survey, response]);

    const isSelected = (index) => {
        let tempSkills = localSkills.slice()
        tempSkills[index].isSelected = !tempSkills[index].isSelected;
        setLocalSkills(tempSkills)

    }

    const joyRating = (index, rating) => {
        let tempSkills = localSkills.slice()
        tempSkills[index].joyRating = rating;
        setLocalSkills(tempSkills)
    }

    function buildSkillsList() {
        let skillsJsx = []

        skillsJsx = localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )

    }

    const skillRatingJsx  = (skill, index) => {
        return (
            <Grid key={skill.title} item lg={6}>
                <Tooltip title={skill.description ? skill.description : ""} aria-label="description" arrow placement="top">
                    <Paper elevation={3} className={classes.skillItem}>
                        <div onClick={() => isSelected(index)}>
                            <div className={classes.skillItemHeaderDiv}>
                                {skill.isSelected ? <Typography variant="h6">{skill.title} <Chip color="primary" label="I have it" onClick={()=>{}}/></Typography> : (<Typography variant="h6">{skill.title} <Chip color="default" label="I don't have it" onClick={()=>{}}/></Typography>)}
                            </div>
                        </div>
                        <Collapse in={skill.isSelected} collapsedHeight={0}>
                            <div className={classes.skillItemBodyDiv}>
                                <Divider/>
                                <div style={{paddingTop: 20}}>
                                    {joyRatingJsx(skill, index)}
                                </div>
                            </div>
                        </Collapse>
                    </Paper>
                </Tooltip>
            </Grid>
        )
    }

    const joyRatingJsx = (skill, index) => {
        return (
            <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} className={classes.subHeadingContainer}>
                        <Typography variant="body1">How much do you enjoy doing this?</Typography>
                    </Grid>
                    <Grid onClick={() => joyRating(index, 1)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                        <div className={skill.joyRating === 1 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                            <span role="img" aria-label="frustrated">😩</span>
                        </div>
                        <Typography className={skill.joyRating === 1 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Hate it</Typography>
                    </Grid>
                    <Grid onClick={() => joyRating(index, 2)} item xs={3} className={classes.skillRatingAvatarContainer}>
                        <div className={skill.joyRating === 2 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                            <span role="img" aria-label="content">🙂</span>
                        </div>
                        <Typography className={skill.joyRating === 2 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Don't Mind</Typography>
                    </Grid>
                    <Grid onClick={() => joyRating(index, 3)} item xs={3} className={classes.skillRatingAvatarContainer}>
                        <div className={skill.joyRating === 3 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                            <span role="img" aria-label="love">😍</span>
                        </div>
                        <Typography className={skill.joyRating === 3 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Love it</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    const save = async () => {
        setIsSaving(true)
        let tempResponse = Object.assign({}, response)

        localSkills.forEach(skill => {
            tempResponse.skillGroups[skill.skillGroupId][skill.id].isSelected = skill.isSelected
            tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
            tempResponse.skillGroups[skill.skillGroupId][skill.id].joyRating = skill.joyRating
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    const skillListJsx = buildSkillsList()

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.title}</Typography>
                            <Typography className={classes.subHeading} variant="h5">{segment.description}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {skillListJsx}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
