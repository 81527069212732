import React, {useEffect, useState} from 'react';
import {AppBar, Button, Checkbox, FormControlLabel, Hidden, Toolbar} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ReadMoreText from "../util/ReadMoreText";
import FullScreenLoading from "../FullScreenLoading";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center",
        paddingBottom: '10px'
    },
    subHeading: {
        paddingBottom: "10px"
    },
    categoryContainer: {
        borderBottom: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#afafaf'
    },
    categoryContainerLeft: {
        borderRight: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#afafaf'
    },
    categoryTextContainer: {
        padding: '10px',
        position: 'sticky',
        top: 0
    },
    categoryTextContainerMobile: {
        width: '100vw',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginLeft: '-15px',
        marginRight: '-15px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        boxShadow: '0px 2px 6px #6b6b6b',
        background: "white"
    },
    categoryTitle: {
        paddingBottom: '5px',
        fontWeight: 'bold'
    },
    skillTitle: {
        fontWeight: 'bold',
        paddingBottom: '6px'
    },
    prompt: {
        fontWeight: 'bold'
    },
    skillItemDescription: {
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden'
    },
    skillItemHeaderDiv: {
        padding: 20
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    skillItem: {
        margin: '20px',
        textAlign: "left",
        border: "solid",
        borderWidth: '1px',
        borderRadius: '15px',
        borderColor: '#afafaf'
    },
    saveButtonContainer: {
        padding: "50px",
        marginBottom: 24
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
}))

const MAX_SELECTIONS = 3


export default function LearnStep({survey, segment, response, saveResponse, getNextUrl, isReview}) {

    const classes = useStyles();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [categorizedSkills, setCategorizedSkills] = useState({});
    const [selectedCount, setSelectedCount] = useState(0)


    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        const tempResponse = Object.assign(response, {})
        const categorizedSkills = {}
        let selected = 0

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.isLearn = tempResponse.skillGroups[skillGroupId][skillId].isLearn ? tempResponse.skillGroups[skillGroupId][skillId].isLearn : false

                if (tempResponse.skillGroups[skillGroupId][skillId].isLearn) {
                    selected++
                }

                if (!categorizedSkills[skillGroupId]) {
                    categorizedSkills[skillGroupId] = []
                }
                categorizedSkills[skillGroupId].push(skill)
            })
        })

        setSelectedCount(selected)
        setCategorizedSkills(categorizedSkills)

        setIsLoading(false)
    },[survey, segment, response]);

    //Save any time the user navigates away from this component
    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                const tempResponse = Object.assign({}, response)

                Object.entries(categorizedSkills).forEach(([skillGroupId, skills]) => {
                    skills.forEach(skill => {
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].isLearn = skill.isLearn
                    })
                })

                try {
                    saveResponse(tempResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [response, saveResponse, categorizedSkills, location])

    const learnSkill = (index, skillGroupId) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)

        if (tempCategorizedSkills[skillGroupId][index].isLearn) {
            tempCategorizedSkills[skillGroupId][index].isLearn = !tempCategorizedSkills[skillGroupId][index].isLearn
            setCategorizedSkills(tempCategorizedSkills)
            setSelectedCount(selectedCount - 1)
        } else if (selectedCount < MAX_SELECTIONS){
            tempCategorizedSkills[skillGroupId][index].isLearn = !tempCategorizedSkills[skillGroupId][index].isLearn
            setCategorizedSkills(tempCategorizedSkills)
            setSelectedCount(selectedCount + 1)
        }

        // setSelectedCount(selectedCount+1)

    }

    const buildSkillsList = (skills, skillGroupId) => {
        let skillsJsx = []

        skillsJsx = skills.map((skill, index) => {
            return skillRatingJsx(skill, index, skillGroupId)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )

    }

    const skillRatingJsx  = (skill, index, skillGroupId) => {
        return (
            <Grid key={skill.id} item lg={6}>
                <div className={classes.skillItem}>
                    <div className={classes.skillItemHeaderDiv}>
                        <Typography variant="h6" className={classes.skillTitle}>{skill.title}</Typography>
                        <ReadMoreText text={skill.description}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={skill.isLearn}
                                    onChange={() => learnSkill(index, skillGroupId)}
                                    name="checkedIsLearn"
                                    color="primary"
                                />
                            }
                            label={isReview ? response.targetUserGivenName + ' wants to develop this skill' :"I want to develop this skill"}
                        />
                    </div>
                </div>
            </Grid>
        )
    }

    const buildCategorizedSkills = () => {
        const jsx = [];
        Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
            const category = survey.skillGroupsMeta[skillGroupId]
            jsx.push(
                <Grid item key={skillGroupId} xs={12} className={classes.categoryContainer}>
                    <Grid container direction="row">
                        <Hidden smDown>
                            <Grid item md={3} className={classes.categoryContainerLeft}>
                                <div className={classes.categoryTextContainer}>
                                    <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                                    <Typography variant='body1'>{category.description}</Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <div className={classes.categoryTextContainerMobile}>
                                <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                            </div>
                        </Hidden>
                        <Grid item sm={12} md={9}>
                            <Grid container direction="row" spacing={0}>
                                {buildSkillsList(skills, skillGroupId)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }))

        if (jsx.length) {
            return jsx
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isLoading ?
                <FullScreenLoading/>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography variant='h4' style={{marginBottom: '10px'}}>{isReview ? <span>Select <strong>{MAX_SELECTIONS}</strong> skills you believe <strong>{response.targetUserGivenName} {response.targetUserFamilyName}</strong> is interested in learning</span> : <span>Select the <strong>{MAX_SELECTIONS}</strong> skills that you're most interested in learning</span>}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#afafaf'}}>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildCategorizedSkills()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
                    </Grid>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar variant="regular">
                            {selectedCount === MAX_SELECTIONS ? <span><strong>You have selected {selectedCount} out of {MAX_SELECTIONS}</strong></span> : <span>You have selected {selectedCount} out of {MAX_SELECTIONS}</span>}
                        </Toolbar>
                    </AppBar>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
