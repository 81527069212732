export function getSelfRatingMarks(survey) {
    if (survey.rubric && Array.isArray(survey.rubric)) {
        return survey.rubric.map((step, index) => {
            return {
                value: (index + 1),
                label: step.label
            }
        })
    }

    return [
        {value: 1, label: '1',},
        {value: 2, label: '2',},
        {value: 3, label: '3',},
        {value: 4, label: '4',},
        {value: 5, label: '5',},
        {value: 6, label: '6',},
        {value: 7, label: '7',},
        {value: 8, label: '8',}
    ];
}

export function getSelfRatingDescription(survey, value) {
    const index = value - 1
    const defaultDescriptions =  [
        'Can complete narrow and limited tasks requiring basic skills with direct supervision or guidance.',
        'Can complete routine technical tasks requiring basic skills with general supervision or guidance.',
        'Can process less structured but well-defined technical tasks, even if they are not routine, with occasional general supervision or guidance.',
        'Can complete some specialised and complex tasks without guidance. Can use knowledge of theory and practical experience to choose principles and processes.',
        'Can complete many broad, complex tasks. Can use knowledge of theory and practical experience to choose principles and processes for technical tasks without supervision or guidance.',
        'Master of completing broad, complex tasks in several sub-specialties of the skill. Can use knowledge of theory and practical experience in all of those sub-specialties.',
        'Can complete new and complex tasks, including choosing the best processes for particular situations. Can use advanced specialised knowledge or processes.',
        'Can develop innovative new solutions or research using a wide range of scientific and creative strategies.'
    ]

    if (survey.rubric && Array.isArray(survey.rubric) && survey.rubric[index] && survey.rubric[index].description) {
        return survey.rubric[index].description
    }

    if (defaultDescriptions[index]) {
        return defaultDescriptions[index]
    }

    return ''
}
