import React, {useEffect, useState} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel, Hidden, Slider} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ReadMoreText from "../util/ReadMoreText";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center",
        paddingBottom: '10px'
    },
    subHeading: {
        paddingBottom: "10px"
    },
    categoryContainer: {
        borderBottom: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#afafaf'
    },
    categoryContainerLeft: {
        borderRight: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#afafaf'
    },
    categoryTextContainer: {
        padding: '10px',
        position: 'sticky',
        top: 0
    },
    categoryTextContainerMobile: {
        width: '100vw',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginLeft: '-15px',
        marginRight: '-15px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        boxShadow: '0px 2px 6px #6b6b6b',
        background: "white"
    },
    categoryTitle: {
        paddingBottom: '5px',
        fontWeight: 'bold'
    },
    skillTitle: {
        fontWeight: 'bold',
        paddingBottom: '6px'
    },
    prompt: {
        fontWeight: 'bold'
    },
    skillItemDescription: {
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden'
    },
    skillItemHeaderDiv: {
        padding: 20
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    skillItem: {
        margin: '20px',
        textAlign: "left",
        border: "solid",
        borderWidth: '1px',
        borderRadius: '15px',
        borderColor: '#afafaf'
    },
    skillSectionContainer: {
        paddingTop: 20
    },
    joyRatingContainer: {
        textAlign: 'center'
    },
    joyRatingText: {
        padding: '5px',
        fontSize: "small"
    },
    joyRatingTextSelected: {
        fontSize: "small",
        fontWeight: "bold"
    },
    joyRatingAvatarContainer: {
        cursor: "pointer"
    },
    joyRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    joyRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingContainer: {
        margin: "10px",
        minHeight: "92px"
    },
    skillRatingTitle: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: "small",
        backgroundColor: '#efefef'
    },
    saveButtonContainer: {
        padding: "50px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    }
}))

export default function MentorSelectionSegment({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const location = useLocation()

    const [isLoading, setIsLoading] = useState(true);
    const [categorizedSkills, setCategorizedSkills] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        const tempResponse = Object.assign(response, {})
        const categorizedSkills = {}

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.mentors = tempResponse.skillGroups[skillGroupId][skillId].mentors ? tempResponse.skillGroups[skillGroupId][skillId].mentors : {}

                if (!categorizedSkills[skillGroupId]) {
                    categorizedSkills[skillGroupId] = []
                }
                categorizedSkills[skillGroupId].push(skill)
            })
        })

        setCategorizedSkills(categorizedSkills)

        setIsLoading(false)
    },[segment, survey, response]);

    //Save any time the user navigates away from this component
    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                let tempResponse = Object.assign({}, response)

                Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
                    skills.forEach(skill => {
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].mentors = skill.mentors
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].category = skill.category
                    })
                }))

                try {
                    saveResponse(tempResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [response, saveResponse, categorizedSkills, location])

    function buildSkillsList(skills, skillGroupId) {

        const skillsJsx = skills.map((skill, index) => {
            return skillJsx(skill, index, skillGroupId)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    const toggleMentor = (index, skillGroupId, mentor) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)
        tempCategorizedSkills[skillGroupId][index].mentors[mentor] = !tempCategorizedSkills[skillGroupId][index].mentors[mentor]
        setCategorizedSkills(tempCategorizedSkills)
    }

    // const selfRating = (index, skillGroupId, rating) => {
    //     let tempCategorizedSkills = Object.assign({}, categorizedSkills)
    //     tempCategorizedSkills[skillGroupId][index].selfRating = rating
    //     setCategorizedSkills(tempCategorizedSkills)
    // }

    const renderCheckboxes = (skill, index, skillGroupId) => {
        let jsx = '';
        if (segment.mentors && Array.isArray(segment.mentors)) {
            jsx = segment.mentors.map((mentor, mentorIndex) => {
                return (
                    <FormControlLabel key={skill.id + mentorIndex}
                        control={
                            <Checkbox
                                checked={skill.mentors[mentor]}
                                onChange={() => toggleMentor(index, skillGroupId, mentor)}
                                name={"checkMentor-" + mentor}
                                color="primary"
                            />
                        }
                        label={mentor}
                    />
                )
            })
        }

        return jsx
    }

    const skillJsx  = (skill, index, skillGroupId) => {
        return (
            <Grid key={skill.id} item lg={6}>
                <div className={classes.skillItem}>
                    <div className={classes.skillItemHeaderDiv}>
                        <Typography variant="h6" className={classes.skillTitle}>{skill.title}</Typography>
                        <ReadMoreText text={skill.description}/>
                        {renderCheckboxes(skill, index, skillGroupId)}
                    </div>
                </div>
            </Grid>
        )
    }

    const buildCategorizedSkills = () => {
        const jsx = [];
        Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
            const category = survey.skillGroupsMeta[skillGroupId]
            jsx.push(
                <Grid item key={skillGroupId} xs={12} className={classes.categoryContainer}>
                    <Grid container direction="row">
                        <Hidden smDown>
                            <Grid item md={3} className={classes.categoryContainerLeft}>
                                <div className={classes.categoryTextContainer}>
                                    <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                                    <Typography variant='body1'>{category.description}</Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <div className={classes.categoryTextContainerMobile}>
                                <Typography variant='h6' className={classes.categoryTitle}>{category.title}</Typography>
                            </div>
                        </Hidden>
                        <Grid item sm={12} md={9}>
                            <Grid container direction="row" spacing={0}>
                                {buildSkillsList(skills, skillGroupId)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }))

        if (jsx.length) {
            return jsx
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography variant='h4' style={{fontWeight: 'bold', marginBottom: '10px'}}>Select the best mentors for each skill</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} style={{borderTop: 'solid', borderTopWidth: '1px', borderTopColor: '#afafaf'}}>
                            <Grid container direction="row" justify="center">
                                {buildCategorizedSkills()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
