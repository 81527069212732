import {AppBar, Toolbar} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 0,
        bottom: 'auto',
        marginBottom: 48
    },
}))


export default function ReviewerBanner({isReview, response}) {

    const classes = makeStyles()

    if (!isReview) {
        return ''
    }

    return (
        <React.Fragment>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar variant="regular">
                {<span>You are currently reviewing <strong>{response.targetUserGivenName} {response.targetUserFamilyName}</strong></span>}
            </Toolbar>
        </AppBar>
        <div style={{height: 64}}></div>
        </React.Fragment>
    )
}
