import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Divider, FormControlLabel, Hidden, Radio, RadioGroup} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {Element, scroller} from "react-scroll";


const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center",
        paddingBottom: '10px'
    },
    subHeading: {
        paddingBottom: "10px"
    },
    categoryTitle: {
        paddingBottom: '16px',
        marginTop: 80,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    skillTitle: {
        // fontWeight: 'bold',
        fontSize: 18,
        textAlign: "center",
        paddingBottom: '6px'
    },
    skillItemHeaderDiv: {
        padding: 20
    },
    skillItemBodyDiv: {
        // paddingLeft: 20,
        // paddingRight: 20,
        paddingBottom: 20
    },
    skillItem: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    skillItemInActive: {
        marginBottom: '20px',
        marginTop: '20px',
        opacity: 0.4
    },
    skillSectionContainer: {
        paddingTop: 20
    },
    saveButtonContainer: {
        padding: "50px",
        paddingBottom: '450px'
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    largeRadioButton: {
        "& svg": {
            width: "2.2em",
            height: "2.2em"
        }
    },
    mediumRadioButton: {
        "& svg": {
            width: "1.7em",
            height: "1.7em"
        }
    },
    smallerRadioButton: {
        "& svg": {
            width: "1.3em",
            height: "1.3em"
        }
    }
}))

export default function SingleAnswerMultipleChoiceSegment({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const location = useLocation()

    const [isLoading, setIsLoading] = useState(true);
    const [categorizedSkills, setCategorizedSkills] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        const tempResponse = Object.assign(response, {})
        const categorizedSkills = {}

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.rating = tempResponse.skillGroups[skillGroupId][skillId].rating ? tempResponse.skillGroups[skillGroupId][skillId].rating : null

                if (!categorizedSkills[skillGroupId]) {
                    categorizedSkills[skillGroupId] = []
                }
                categorizedSkills[skillGroupId].push(skill)
            })
        })

        setCategorizedSkills(categorizedSkills)

        setIsLoading(false)
    },[segment, survey, response]);

    //Save any time the user navigates away from this component
    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                let tempResponse = Object.assign({}, response)

                Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
                    skills.forEach(skill => {
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].category = skill.category
                        tempResponse.skillGroups[skill.skillGroupId][skill.id].rating = skill.rating
                    })
                }))

                try {
                    saveResponse(tempResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [response, saveResponse, categorizedSkills, location])

    const rating = (index, skillGroupId, rating) => {
        let tempCategorizedSkills = Object.assign({}, categorizedSkills)
        tempCategorizedSkills[skillGroupId][index].rating = rating
        setCategorizedSkills(tempCategorizedSkills)

        let nextId = 'save-button'
        if (tempCategorizedSkills[skillGroupId][index + 1]) {
            nextId = tempCategorizedSkills[skillGroupId][index + 1].id
        } else {
            let isNextGroup = false
            Object.entries(categorizedSkills).forEach(([iteratedSkillGroupId, skills]) => {
                if (isNextGroup) {
                    if (categorizedSkills[iteratedSkillGroupId][0] && categorizedSkills[iteratedSkillGroupId][0].id) {
                        nextId = categorizedSkills[iteratedSkillGroupId][0].id
                    }
                    isNextGroup = false
                }

                if (iteratedSkillGroupId === skillGroupId) {
                    isNextGroup = true
                }
            })
        }

        scroller.scrollTo(nextId, {
            duration: 500,
            // delay: 100,
            smooth: true,
            // containerId: 'ContainerElementID',
            offset: -350, // Scrolls to element + 50 pixels down the page
        })
    }

    function buildSkillsList(skills, skillGroupId) {

        const skillsJsx = skills.map((skill, index) => {
            return skillJsx(skill, index, skillGroupId)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    const skillJsx  = (skill, index, skillGroupId) => {
        return (
            <Grid key={skill.id} item xs={12}>
                <div className={skill.rating ? classes.skillItemInActive : classes.skillItem} >
                    <div className={classes.skillItemHeaderDiv}>
                        <Typography variant="body1" className={classes.skillTitle}>{skill.title}</Typography>
                    </div>
                    <Element name={skill.id}>
                        <div className={classes.skillItemBodyDiv}>
                            <div className={classes.skillSectionContainer}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    {/*<Grid item xs={12}>*/}
                                    <Hidden smDown>
                                        <span style={{marginRight: 25, fontSize: 20}}>Disagree</span>
                                        {renderRadioButtons(survey.rubric, skill, index, skillGroupId)}
                                        <span style={{fontSize: 20}}>Agree</span>
                                    </Hidden>
                                    <Hidden mdUp>
                                        {renderRadioButtons(survey.rubric, skill, index, skillGroupId)}
                                        <div style={{width: '50%', textAlign: "start"}}>
                                            <span style={{fontSize: 20}}>Disagree</span>
                                        </div>
                                        <div style={{width: '50%', textAlign: "end"}}>
                                            <span style={{fontSize: 20, marginRight: 16}}>Agree</span>
                                        </div>
                                    </Hidden>
                                    {/*</Grid>*/}
                                </Grid>
                            </div>
                        </div>
                    </Element>
                </div>
                {/*<div></div>*/}
                <Divider/>
            </Grid>
        )
    }

    function renderRadioButtons(rubric, skill, index, skillGroupId) {
        if (rubric && Array.isArray(rubric))
        return (
            <RadioGroup row aria-label="gender" name="gender1" value={skill.rating} onChange={(event)=> rating(index, skillGroupId, event.target.value)} >
                {rubric.map(((level, index) => {
                    if (index === 0 || index === rubric.length - 1) {
                        return <FormControlLabel key={level.label} label={''} className={classes.largeRadioButton} value={level.label} control={<Radio />} />
                    }
                    if (index === 1 || index === rubric.length - 2) {
                        return <FormControlLabel key={level.label} label={''} className={classes.mediumRadioButton} value={level.label} control={<Radio />}  />
                    }
                    if (index === 2 || index === rubric.length - 3) {
                        return <FormControlLabel key={level.label} label={''} className={classes.smallerRadioButton} value={level.label} control={<Radio />}  />
                    }
                    return <FormControlLabel key={level.label} label={''} value={level.label} control={<Radio size={"medium"} />}  />
                }))}
            </RadioGroup>
        )
    }

    const buildCategorizedSkills = () => {
        const jsx = [];
        Object.entries(categorizedSkills).forEach((([skillGroupId, skills]) => {
            const category = survey.skillGroupsMeta[skillGroupId]
            jsx.push(
                <Grid item key={skillGroupId} xs={12}>
                    <Grid container direction="row" justify={"center"}>
                        <Grid item sm={12} md={12}>
                            <Typography variant='h6' className={classes.categoryTitle}>{category.description}</Typography>
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <Grid container direction="row" spacing={0}>
                                {buildSkillsList(skills, skillGroupId)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }))

        if (jsx.length) {
            return jsx
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    {/*<Grid container direction="row" justify="center" alignItems="center">*/}
                    {/*    <Grid item xs={12} className={classes.subHeadingContainer}>*/}
                    {/*        <Typography variant='h4' style={{fontWeight: 'bold', marginBottom: '10px'}}>Select your skills</Typography>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} style={{borderTopColor: '#afafaf'}}>
                            <Grid container direction="row" justify="center">
                                {buildCategorizedSkills()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        <Element name='save-button'>
                            <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
                        </Element>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
