import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {StepButton, withStyles} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";

const Connector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingBottom: "20px"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

export default function SurveyStepper({steps, activeStep, navigateToStep}) {
    const classes = useStyles();

    let stepsJsx = []

    function handleStepClick(index) {
        navigateToStep(index)
    }

    if (steps) {
        stepsJsx = steps.map((segment, index) => {
            return (
                <Step key={segment.title + index}>
                    <StepButton completed={activeStep > index} onClick={() => handleStepClick(index)}>
                        <StepLabel>{segment.title}</StepLabel>
                    </StepButton>
                </Step>
            )
        })
    }

    return (
        <React.Fragment>
            { steps.length > 1 ?
                <div className={classes.root}>
                    {/*<Stepper nonLinear activeStep={parseInt(activeStep)} alternativeLabel connector={<Connector/>}>*/}
                    <Stepper nonLinear activeStep={parseInt(activeStep)} alternativeLabel>
                        {stepsJsx}
                    </Stepper>
                </div>
                :
                ''
            }
        </React.Fragment>
    );
}
