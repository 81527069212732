import React from 'react';
import logo from '../static/skillpaca_logo_full_lockup.svg';
import {Hidden} from "@material-ui/core";

function Logo({logoUrl}) {

    const backgroundUrl = logoUrl ? logoUrl : logo

    const styleDesktop = {
        width: "100%",
        height:"50px",
        margin: "20px",
        background: "url(" + backgroundUrl +")",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    }

    const styleMobile = {
        width: "100%",
        height:"35px",
        margin: "20px",
        background: "url(" + backgroundUrl +")",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    }

    return (
        <React.Fragment>
            <Hidden smDown>
                <div style={styleDesktop}/>
            </Hidden>
            <Hidden mdUp>
                <div style={styleMobile}/>
            </Hidden>
        </React.Fragment>
    )
}

export default Logo;
