import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import bookstack from "../static/bookstack.svg";
import Reward from "react-rewards";
import Logo from "./Logo";
import {Button, Container} from "@material-ui/core";
import {fetchSurvey} from "../service/backendApi";
import FullScreenLoading from "./FullScreenLoading";
import {useParams} from "react-router-dom";
import LaunchIcon from '@material-ui/icons/Launch';

let reward;
let rewardTwo
let rewardThree
let rewardFour

const rewardConfig = {
    lifetime: 30,
    angle: 275,
    spread: 150,
    startVelocity: 25,
    elementCount: 35,
    springAnimation: false
}

export default function Complete() {

    const {tenantId, surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState({});
    const [isRedirecting, setIsRedirecting] = useState(false)

    useEffect(() => {
        fetchSurvey(tenantId, surveyId).then(survey => {
            setSurvey(survey)
            setIsLoading(false)
        })
    }, [tenantId, surveyId])

    useEffect(() => {
        if (!isLoading && reward && reward.rewardMe) {
            setTimeout(() => {
                reward.rewardMe()
            }, 600)
            setTimeout(() => {
                reward.rewardMe()
            }, 650)
            setTimeout(() => {
                rewardTwo.rewardMe()
            }, 800)
            setTimeout(() => {
                rewardTwo.rewardMe()
            }, 850)
            setTimeout(() => {
                rewardThree.rewardMe()
            }, 1000)
            setTimeout(() => {
                rewardThree.rewardMe()
            }, 1050)
            setTimeout(() => {
                rewardFour.rewardMe()
            }, 1200)
            setTimeout(() => {
                rewardFour.rewardMe()
            }, 1250)
            // setTimeout(() => {
            //     rewardThree.rewardMe()
            // }, 1800)
            // setTimeout(() => {
            //     rewardThree.rewardMe()
            // }, 1850)
            // setTimeout(() => {
            //     rewardTwo.rewardMe()
            // }, 2100)
            // setTimeout(() => {
            //     rewardTwo.rewardMe()
            // }, 2150)
            // setTimeout(() => {
            //     reward.rewardMe()
            // }, 2400)
            // setTimeout(() => {
            //     reward.rewardMe()
            // }, 2450)
        }
    }, [isLoading])

    if (isLoading) {
        return <Container><FullScreenLoading/></Container>
    }


    return (
        <React.Fragment>
            <div style={{width: '100%', display: 'flex'}}>
                <div style={{width: '20%',height: '1px'}}></div>
                <div style={{width: '20%',height: '1px'}}>
                    <Reward
                        ref={(ref) => { reward = ref }}
                        type='confetti'
                        config={rewardConfig}><span></span>
                    </Reward>
                </div>
                <div style={{width: '20%',height: '1px'}}>
                    <Reward
                        ref={(ref) => { rewardTwo = ref }}
                        type='confetti'
                        config={rewardConfig}><span></span>
                    </Reward>
                </div>
                <div style={{width: '20%',height: '1px'}}>
                    <Reward
                        ref={(ref) => { rewardThree = ref }}
                        type='confetti'
                        config={rewardConfig}><span></span>
                    </Reward>
                </div>
                <div style={{width: '20%',height: '1px'}}>
                    <Reward
                        ref={(ref) => { rewardFour = ref }}
                        type='confetti'
                        config={rewardConfig}><span></span>
                    </Reward>
                </div>
            </div>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo logoUrl={survey.logoUrl}/>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" style={{textAlign: 'center'}}>
                    <Grid item xs={12} style={{marginTop: '36px', marginBottom: '36px'}}>
                        <img src={bookstack} height={"150px"} alt={"Book Stack Woman"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" style={{paddingBottom: '16px'}}>Woohoo you made it!! 🎉🎉</Typography>
                        <Typography variant="h6" style={{paddingBottom: '16px'}}>What's next?</Typography>
                        <Typography variant="body1">This data will be used to tailor your career and professional growth according to your unique skillset and aspirations. Ultimately we want to provide you the resources to support your growth!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {survey.redirect ? <Button component={"a"} href={survey.redirect} variant={"contained"} color={"primary"} style={{marginTop: 32}}>My Dashboard&nbsp;<LaunchIcon/></Button> : ''}
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
