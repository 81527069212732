import './App.css';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from "./theme";
import {Route, Routes, useLocation} from "react-router-dom";
import Welcome from "./components/Welcome";
import NotFound from "./components/NotFound";
import SurveyContainer from "./components/SurveyContainer";
import Complete from "./components/Done";
import Practera from "./components/auth/Practera";
import {useEffect} from "react";
import DynamicTheme from "./components/DynamicTheme";

function App() {

    const location = useLocation()

    useEffect(() => {
        window.analytics.page();
    }, [location]);

    return (
        <ThemeProvider theme={theme("#335eea")}>
            <Routes>
                <Route path="/welcome/:tenantId/:surveyId" element={<DynamicTheme><Welcome/></DynamicTheme>}/>
                <Route path="/complete/:tenantId/:surveyId" element={<DynamicTheme><Complete/></DynamicTheme>}/>
                <Route path="/practera/:tenantId/:surveyId" element={<DynamicTheme><Practera/></DynamicTheme>}/>
                <Route path="/survey/:tenantId/:surveyId" element={<DynamicTheme><SurveyContainer/></DynamicTheme>}/>
                <Route path="/survey/:tenantId/:surveyId/:segmentIndex"
                       element={<DynamicTheme><SurveyContainer/></DynamicTheme>}/>
                <Route path="/*" element={<NotFound/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
