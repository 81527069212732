import React, {useEffect, useState} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    skillItemSelected: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.custom.skillItemBackground,
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    paperContainer: {
        padding: "40px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    }
}))


export default function SelectSkillsSegment({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();
    const navigate = useNavigate();

    const [localSkills, setLocalSkills] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const tempResponse = Object.assign(response, {})
        const skills = []

        segment.skillGroupIds.forEach(skillGroupId => {
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.isSelected = tempResponse.skillGroups[skillGroupId][skillId].isSelected ? tempResponse.skillGroups[skillGroupId][skillId].isSelected : false
                skills.push(skill)
            })
        })

        setLocalSkills(skills)
        setIsLoading(false)
    },[survey, segment, response]);

    const save = async () => {
        setIsSaving(true)
        let tempResponse = Object.assign({}, response)

        localSkills.forEach(skill => {
            tempResponse.skillGroups[skill.skillGroupId][skill.id].isSelected = skill.isSelected
            tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
            tempResponse.skillGroups[skill.skillGroupId][skill.id].category = skill.category
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    const selectSkill = async (index) => {
        const updatedSkills = Object.assign([], localSkills);
        updatedSkills[index].isSelected = !updatedSkills[index].isSelected;
        setLocalSkills(updatedSkills)
    }

    const renderSkillsList = () => {
        const skillsList = [];
        localSkills.forEach((skill, index) => {
            skillsList.push(
                <Grid key={index} item lg={6}>
                    <Paper onClick={() => selectSkill(index)} className={skill.isSelected ? classes.skillItemSelected : classes.skillItem} elevation={3} >{skill.title}</Paper>
                </Grid>
            )
        })
        return skillsList;
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Calculating essential skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Paper elevation={3} className={classes.paperContainer}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="h5">{segment.title}</Typography>
                                <Typography variant="subtitle1">{segment.description}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={5}>
                                <img src={segment.imageUrl} height={"250px"} alt={"Computer man"} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <div>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={10} className={classes.subHeadingContainer} >
                                <Typography variant="h6"><b>Select the skills you have.</b></Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    {renderSkillsList()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>

            }
        </React.Fragment>

    );
}

