import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import FullScreenLoading from "./FullScreenLoading";
import {MuiThemeProvider} from "@material-ui/core";
import {theme} from "../theme";
import {fetchSurvey} from "../service/backendApi";

export default function DynamicTheme({children}) {
    const {tenantId, surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [survey, setSurvey] = useState()

    useEffect(() => {
        setIsLoading(true)
        fetchSurvey(tenantId, surveyId).then(survey => {
            setSurvey(survey)
            setIsLoading(false)
        })
    }, [tenantId, surveyId])

    if (isLoading) {
        return <FullScreenLoading/>
    }

    if (survey) {
        return <MuiThemeProvider theme={theme(survey.primaryColor)}>{children}</MuiThemeProvider>
    }

    return <React.Fragment>{children}</React.Fragment>
}
