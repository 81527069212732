import React, {useEffect, useState} from "react";
import {
    authenticate,
    fetchResponse,
    fetchSurvey,
    generateResponse,
    getJwt,
    removeJwt,
    saveResponse, setJwt
} from "../service/backendApi";
import {Container} from "@material-ui/core";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SelectSkillsSegment from "./segments/SelectSkillsSegment";
import Grid from "@material-ui/core/Grid";
import Logo from "./Logo";
import SurveyStepper from "./SurveyStepper";
import JoyStep from "./segments/JoyStep";
import RatingStep from "./segments/RatingStep";
import LearnStep from "./segments/LearnStep";
import ExperienceStep from "./segments/ExperienceStep";
import AddToTeamStep from "./segments/AddToTeamStep";
import CurrentlyUsing from "./segments/CurrentlyUsing";
import FullScreenLoading from "./FullScreenLoading";
import SegmentNotFound from "./segments/SegmentNotFound";
import SelectJoyAndSelfRatingSegment from "./segments/SelectJoyAndSelfRatingSegment";
import SelectAndJoyRating from "./segments/SelectAndJoyRatingSegment";
import LevelsRubricSegment from "./segments/LevelsRubricSegment";
import MentorSelectionSegment from "./segments/MentorSelectionSegment";
import AdvancedRubricSegment from "./segments/AdvancedRubricSegment";
import ReviewerBanner from "./ReviewerBanner";
import SingleAnswerMultipleChoiceSegment from "./segments/SingleAnswerMultipleChoiceSegment";
import DemographicsSegment from "./segments/DemographicsSegment";

export default function SurveyContainer() {
    const {tenantId, surveyId, segmentIndex} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [survey, setSurvey] = useState(undefined);
    const [steps, setSteps] = useState([]);
    const [response, setResponse] = useState({})
    const [isReview, setIsReview] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        setIsLoading(true)

        const params = new URLSearchParams(location.search)
        let jwt = params.get('jwt');
        console.log('jwt param', jwt)
        if (jwt) {
            setJwt(surveyId, jwt)
        } else {
            jwt = getJwt(surveyId);
        }

        if (!jwt) {
            authenticate(tenantId, surveyId).then(data => {
                generateResponse(tenantId, surveyId).then(response => {
                    navigate('/survey/' + tenantId + '/' + surveyId + '/' + 0)
                    setResponse(response)
                })
            })
        } else {
            if (!segmentIndex) {
                navigate('/survey/' + tenantId + '/' + surveyId + '/' + 0)
            }

            if (!survey || !response) {
                const fetchResponsePromise = fetchResponse(tenantId, surveyId)
                const fetchSurveyPromise = fetchSurvey(tenantId, surveyId);
                Promise.all([fetchResponsePromise, fetchSurveyPromise]).then(([responseData, surveyData]) => {

                    if (!responseData) {
                        console.error('Error response could not be found')
                        removeJwt(surveyId)
                        navigate('/welcome/' + tenantId + '/' + surveyId)
                        return
                    }
                    if (!surveyData) {
                        console.error('Survey could not be found')
                        navigate('/welcome/' + tenantId + '/' + surveyId)
                        return
                    }

                    setResponse(responseData)
                    setSurvey(surveyData)
                    setSteps(surveyData.structure)
                    setIsLoading(false)

                    if (responseData.targetUsername && responseData.username && responseData.targetUsername !== responseData.username) {
                        setIsReview(true)
                    }

                }).catch(error => {
                    console.error(error)
                })
            } else {
                setIsLoading(false)
            }
        }
    }, [tenantId, surveyId, segmentIndex, navigate, response, survey])

    const saveResponseContainer = async (response) => {
        setIsSaving(true)
        if (response.status !== "COMPLETED") {
            if (parseInt(segmentIndex) + 1 >= steps.length) {
                response.status = "COMPLETED"
            } else {
                response.status = "IN_PROGRESS"
            }
        }
        response = await saveResponse(tenantId, surveyId, response)
        sendSegmentIdentify(response.id)
        setResponse(response)
        setIsSaving(false)
    }

    const getNextUrl = () => {
        if (parseInt(segmentIndex) + 1 < steps.length) {
            return '/survey/' + tenantId + '/' + surveyId + '/' + (parseInt(segmentIndex) + 1)
        } else {
            // if (survey.redirect) {
            //     return '/complete/' + tenantId + '/' + surveyId
            // } else {
                return '/complete/' + tenantId + '/' + surveyId
            // }
        }
    }

    const navigateToStep = (stepIndex) => {
        navigate('/survey/' + tenantId + '/' + surveyId + '/' + stepIndex)
    }

    const sendSegmentIdentify = (id) => {
        window.analytics.identify(id, {
            tenant_id: tenantId
        });

        window.analytics.group(tenantId);
    }

    const renderSegment = (steps, segmentIndex) => {
        const step = steps[segmentIndex ? segmentIndex : 0];
        const segment = survey.structure[segmentIndex ? segmentIndex : 0];

        switch (step.type) {
            case 'selectSkills':
                return <SelectSkillsSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'currentlyUsing':
                return <CurrentlyUsing survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'experience':
                return <ExperienceStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'rating':
                return <RatingStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'joy':
                return <JoyStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'learn':
                return <LearnStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'addToTeam':
                return <AddToTeamStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'selectAndJoyRating':
                return <SelectAndJoyRating survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'selectJoyAndSelfRating':
                return <SelectJoyAndSelfRatingSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'levelsRubric':
                return <LevelsRubricSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'mentorSelection':
                return <MentorSelectionSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'advancedRubric':
                return <AdvancedRubricSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'singleAnswerMultipleChoice':
                return <SingleAnswerMultipleChoiceSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview} />
            case 'demographics':
                return <DemographicsSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} isReview={isReview}/>
            default:
                return <SegmentNotFound getNextUrl={getNextUrl}/>
        }
    }

    return (
        <Container>
            {isLoading ? <FullScreenLoading/> :
                <React.Fragment>
                    <ReviewerBanner isReview={isReview} response={response}/>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Logo logoUrl={survey.logoUrl} />
                    </Grid>
                    <SurveyStepper steps={steps} activeStep={segmentIndex} navigateToStep={navigateToStep} />
                    {isSaving ? <FullScreenLoading/> :renderSegment(steps, segmentIndex)}
                </React.Fragment>
            }
        </Container>
    )
}
