import React, {useEffect, useState} from 'react';
import {Button, Container, Hidden} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {Link, useParams} from "react-router-dom";
import bookstack from "../static/bookstack.svg";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "./Logo";
import {fetchSurvey} from "../service/backendApi";
import FullScreenLoading from "./FullScreenLoading";

const useStyles = makeStyles((theme) => ({
    saveButtonContainer: {
        paddingTop: "50px"
    },
    callout: {
        color: theme.palette.primary.main
    }
}))


function Welcome() {

    const {tenantId, surveyId} = useParams();

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState({});

    useEffect(() => {
        fetchSurvey(tenantId, surveyId).then(survey => {
            setSurvey(survey)
            setIsLoading(false)
        })
    }, [tenantId, surveyId])

    if (isLoading) {
        return <Container><FullScreenLoading/></Container>
    }

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo logoUrl={survey.logoUrl}/>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4">Build your skills profile!</Typography>
                        <Typography variant="subtitle1">Discover your true <span className={classes.callout}><strong>potential</strong></span> and value. Get <span className={classes.callout}><strong>recognised</strong></span> for the skills your bring to your role and identify key areas where <span className={classes.callout}><strong>you</strong></span> want to <span className={classes.callout}><strong>upskill</strong></span>.</Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={bookstack} height={"250px"} alt={"Book Stack Woman"} />
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                    <Button component={Link} to={"/survey/" + tenantId + "/" + surveyId} size="large" variant="contained" color="primary">Get started →</Button>
                </Grid>
            </Container>
        </React.Fragment>

    );
}

export default Welcome;
