import React, {useEffect, useState, useMemo} from 'react';
import {Button, CircularProgress, TextField} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {useLocation, useNavigate} from "react-router-dom";
import Select from 'react-select';
import countryList from 'react-select-country-list'
import CreatableSelect from 'react-select/creatable';



const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        paddingTop: '20px'
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subtitle: {
        paddingTop: "10px",
    },
    saveButtonContainer: {
        padding: "50px"
    },
    inputLabel: {
        marginBottom: 16
    },
    inputContainer: {
        paddingTop: 16,
        marginBottom: 32
    }
}));

const genderOptions = [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'other', label: 'Other' },
    { value: 'none', label: 'I would prefer not to say' },
];

const functionOptions = [
    { value: 'accounting', label: 'Accounting/Finance/Economics' },
    { value: 'administration', label: 'Administration' },
    { value: 'consulting', label: 'Consulting' },
    { value: 'communication', label: 'Corporate Affairs/Relations/Communications' },
    { value: 'engineering', label: 'Engineering' },
    { value: 'geology', label: 'Geology/Geochemistry/Metallurgy' },
    { value: 'humanResources', label: 'Human Resources' },
    { value: 'it', label: 'IT/Data Analytics' },
    { value: 'labouring', label: 'Labouring' },
    { value: 'marketing', label: 'Marketing/Sales' },
    { value: 'projectManagement', label: 'Project Management' },
    { value: 'research', label: 'Research' },
    { value: 'safety', label: 'Safety' },
    { value: 'science', label: 'Science' },
    { value: 'training', label: 'Training/Coaching/Teaching' },
];

const levelOptions = [
    { value: 'nonExecutiveDirector', label: 'Non-Executive Director' },
    { value: 'executive', label: 'Executive/Partner' },
    { value: 'generalManager', label: 'General Manager' },
    { value: 'manager', label: 'Manager/Associate' },
    { value: 'supervisor', label: 'Supervisor/team leader' },
    { value: 'technical', label: 'Non-supervisory - Professional/Technical' },
    { value: 'nonSupervisory', label: 'Non-Supervisory' },
    { value: 'graduate', label: 'Graduate/Cadet/Trainee' },
];

const industryOptions = [
    { value: 'bankingAndFinance', label: 'Banking and Finance' },
    { value: 'communityServices', label: 'Community Services' },
    { value: 'construction', label: 'Construction' },
    { value: 'consultingServices', label: 'Consulting Services' },
    { value: 'education', label: 'Education' },
    { value: 'energy', label: 'Energy' },
    { value: 'foodProcessing', label: 'Food Processing' },
    { value: 'governmentFederal', label: 'Government (Federal)' },
    { value: 'governmentState', label: 'Government (State/Province)' },
    { value: 'governmentLocal', label: 'Government (Local)' },
    { value: 'health', label: 'Health' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'it', label: 'IT/Computing' },
    { value: 'logistics', label: 'Logistics/Transport' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'media', label: 'Media' },
    { value: 'mining', label: 'Mining' },
    { value: 'oilAndGas', label: 'Oil and Gas' },
    { value: 'philanthropy', label: 'Philanthropy' },
    { value: 'professionalServicesAccountingLegalTax', label: 'Professional Services (Accounting/Legal/Tax)' },
    { value: 'professionalServicesEngineeringProjectManagementTechnical', label: 'Professional Services (Engineering/Project Management/Architecture/other technical)' },
    { value: 'professionalServicesStrategyHumanResourcesPsychological', label: 'Professional Services (Strategy/Human Resources/Psychological/etc.)' },
    { value: 'retail', label: 'Retail' },
    { value: 'telecommunications', label: 'Telecommunications' },
    { value: 'wholesale', label: 'Wholesale' },
];

export default function DemographicsSegment({survey, response, saveResponse, getNextUrl}) {

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const countryOptions = useMemo(() => countryList().getData(), [])


    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [localResponse, setLocalResponse] = useState({})

    useEffect(() => {
        const tempResponse = Object.assign({}, response)
        if (!tempResponse.demographics) {
            tempResponse.demographics = {}
        }
        tempResponse.demographics.age = tempResponse.demographics.age ? tempResponse.demographics.age : ''
        setLocalResponse(tempResponse)
        setIsLoading(false)
    },[response]);

    useEffect(() => {
        if (localResponse.demographics && localResponse.demographics.gender && localResponse.demographics.age && localResponse.demographics.country && localResponse.demographics.function && localResponse.demographics.level && localResponse.demographics.industry ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [localResponse])

    useEffect(() => {
        return () => {
            if (window.location.pathname !== location.pathname) {
                try {
                    saveResponse(localResponse);
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [saveResponse, localResponse, location])

    const createMenuItems = () => {
        const menuItems =  [];
        menuItems.push(<MenuItem key={0} value={0}>None</MenuItem> )
        for (let i  = 1; i < 25; i++) {
            menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }
        menuItems.push(<MenuItem key={"25+"} value={"25+"}>25+</MenuItem>)
        return menuItems;
    }

    const setValue = (value, key) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.demographics[key] = value
        setLocalResponse(tempResponse)
    }

    const renderSaveButton = () => {
        if (isSaving) {
            return <CircularProgress/>
        }
        if (isDisabled) {
            return <Button size="large" variant="contained" color="primary" disabled>Next →</Button>
        }
        return <Button size="large" variant="contained" color="primary" onClick={() => navigate(getNextUrl())}>Next →</Button>
    }

    return (
        <React.Fragment>
                {
                    isLoading ?
                        <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                            <CircularProgress/>&nbsp;
                            <div>
                                <Typography variant="subtitle1">Loading</Typography>
                            </div>
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.inputContainer}>
                                <Grid item lg={6}>
                                    <Typography align="center" variant="h6" className={classes.inputLabel}>I identify as:</Typography>
                                    <Select
                                        value={localResponse.demographics.gender}
                                        onChange={(value) => setValue(value, 'gender')}
                                        options={genderOptions}
                                        placeholder={"Gender"}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Typography align="center" variant="h6">Age</Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: "45px"}}>
                                <Grid item lg={10}>
                                    <form noValidate autoComplete="off">
                                        <div style={{textAlign: "center"}}>
                                            <FormControl className={classes.formControl}>
                                                <TextField type="number" id="age" value={localResponse.demographics.age} onChange={(event) => setValue(event.target.value, 'age')}/>
                                            </FormControl>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" className={classes.inputContainer}>
                                <Grid item lg={6}>
                                    <Typography align="center" variant="h6" className={classes.inputLabel}>Country of birth</Typography>
                                    <Select
                                        value={localResponse.demographics.country}
                                        isSearchable={true}
                                        onChange={(value) => setValue(value, 'country')}
                                        options={countryOptions}
                                        placeholder={"Country"}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" className={classes.inputContainer}>
                                <Grid item lg={6}>
                                    <Typography align="center" variant="h6" className={classes.inputLabel}>Main function of role</Typography>
                                    <CreatableSelect
                                        value={localResponse.demographics.function}
                                        onChange={(value) => setValue(value, 'function')}
                                        options={functionOptions}
                                        placeholder={"Function"}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" className={classes.inputContainer}>
                                <Grid item lg={6}>
                                    <Typography align="center" variant="h6" className={classes.inputLabel}>Level</Typography>
                                    <CreatableSelect
                                        value={localResponse.demographics.level}
                                        onChange={(value) => setValue(value, 'level')}
                                        options={levelOptions}
                                        placeholder={"Level"}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" className={classes.inputContainer}>
                                <Grid item lg={6}>
                                    <Typography align="center" variant="h6" className={classes.inputLabel}>Primary Industry</Typography>
                                    <CreatableSelect
                                        value={localResponse.demographics.industry}
                                        onChange={(value) => setValue(value, 'industry')}
                                        options={industryOptions}
                                        placeholder={"Primary Industry"}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }

            <Grid container direction="row" justify="center" alignItems="center">
                <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                    {renderSaveButton()}
                </Grid>
            </Grid>
        </React.Fragment>

    );
}
